import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ThreeCol from '../components/ThreeColLayout'
const NotFoundPage = () => (
  <ThreeCol>

    <div class="privacy-policy-container"><h1>Privacy Policy</h1><p>Welcome to our Privacy Policy page! When you use our web services, you trust us with your information. This Privacy Policy is meant to help you understand what data we collect, why we collect it, and what we do with it. This policy is important; we hope you will take time to read it carefully.</p><h2>Information Collection And Use</h2><p>While using our Site, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to your name.</p><h2>Log Data</h2><p>Like many site operators, we collect information that your browser sends whenever you visit our Site ("Log Data"). This Log Data may include information such as your computer's Internet Protocol ("IP") address, browser type, browser version, the pages of our Site that you visit, the time and date of your visit, the time spent on those pages and other statistics.</p><h2>Cookies</h2><p>Cookies are files with small amount of data, which may include an anonymous unique identifier. Cookies are sent to your browser from a web site and stored on your computer's hard drive.</p><h2>Security</h2><p>The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage, is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.</p><h2>Changes To This Privacy Policy</h2><p>This Privacy Policy is effective as of (insert date) and will remain in effect except with respect to any changes in its provisions in the future, which will be in effect immediately after being posted on this page.</p><h2>Contact Us</h2><p>If you have any questions about this Privacy Policy, please contact us.</p></div>
  </ThreeCol>
)

export default NotFoundPage
